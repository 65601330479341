/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import i18next from '../../config/i18next';
import './header.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.navRef = React.createRef();
  }

  toggleMenu = () => {
    if (this.checkReferences) {
      this.navRef.current.classList.toggle('nav--expanded');
      const submenus = this.navRef.current.querySelectorAll('.nav-item');
      for (let submenu of submenus) {
        submenu.classList.remove('nav-item--expanded');
      }
    }
  };

  checkReferences = () => {
    return this.navRef.current;
  };

  navItemClickHandler = event => {
    const { currentTarget } = event;
    event.preventDefault();
    if (this.checkReferences) {
      const submenus = this.navRef.current.querySelectorAll('.nav-item');
      for (let submenu of submenus) {
        if (submenu !== currentTarget.parentElement) {
          submenu.classList.remove('nav-item--expanded');
        }
        currentTarget.parentElement.classList.toggle('nav-item--expanded');
      }
    }
  };

  render() {
    const logo = require('../../../assets/img/Logo-marcar-liberty-new.svg');
    const logo2x = require('../../../assets/img/Logo-marcar-liberty-new.svg');

    return (
      <>
        <section className="header_cnt">
          <header className="header">
            <figure className="header-logo">
              <a href="https://www.liberty.cl/">
                <img
                  className="img"
                  alt="Logo Liberty"
                  src={logo}
                  srcSet={logo2x}
                />
              </a>
            </figure>
            <span
              className="header-menu icon-burguermenu"
              onClick={event => this.toggleMenu()}
            />
            <ul className="header-prenav">
              <li className="item">
                <a
                  className="item-link"
                  href="https://www.liberty.cl/clientes"
                >
                  Clientes
                </a>
              </li>
              <li className="item">
                <a
                  className="item-link"
                  href="https://www.liberty.cl/corredores"
                >
                  Corredores
                </a>
              </li>
              <li className="item">
                <a
                  className="item-link"
                  href="https://www.liberty.cl/colaboradores"
                >
                  Colaboradores
                </a>
              </li>
            </ul>
          </header>
        </section>
        <section className="nav_cnt">
          <nav className="nav" ref={this.navRef}>
            <section className="nav-item">
              <a className="title" href="#" onClick={this.navItemClickHandler}>
                Nuestra Compañía
                <span className="icon icon-chevron-thin-down" />
              </a>
              <ul className="dropdown">
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/nuestra-compania/quienes-somos"
                  >
                    Quiénes Somos
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/nuestra-compania/organizacion"
                  >
                    Organización
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/nuestra-compania/gobierno-corporativo"
                  >
                    Gobierno Corporativo
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/nuestra-compania/publicaciones"
                  >
                    Publicaciones
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/nuestra-compania/sala-de-prensa"
                  >
                    Sala de prensa
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/nuestra-compania/hdi-liberty"
                    >
                      HDI-Liberty
                  </a> 
                </li>               
              </ul>
            </section>
            <section className="nav-item">
              <a className="title" href="#" onClick={this.navItemClickHandler}>
                Nuestros Seguros
                <span className="icon icon-chevron-thin-down" />
              </a>
              <ul className="dropdown">
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/nuestros-seguros/seguro-automotriz"
                  >
                    Seguro Automotriz
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/nuestros-seguros/seguro-de-hogar"
                  >
                    Seguro de Hogar
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/nuestros-seguros/seguros-para-empresas"
                  >
                    Seguros para empresas
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/nuestros-seguros/seguro-automotriz/soap"
                  >
                    SOAP
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/nuestros-seguros/otros-seguros"
                  >
                    Otros seguros
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/nuestros-seguros/liberty-te-aconseja"
                  >
                    Consejos Liberty
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/Cyberseguridad"
                    >
                      Cyberseguridad
                  </a> 
                </li> 
              </ul>
            </section>
            <section className="nav-item">
              <a className="title" href="#" onClick={this.navItemClickHandler}>
                  Centro de Siniestros
                  <span className="icon icon-chevron-thin-down" />
              </a>
              <ul className="dropdown">
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/servicio-al-cliente/nuevos-clientes/asistencias"
                  >
                    Asistencia auto
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/centro-de-siniestros/automotriz"
                  >
                    Automotriz
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://www.liberty.cl/centro-de-siniestros/centro-de-siniestro-de-hogar-y-otros"
                  >
                    Hogar y Otros
                  </a>
                </li>
              </ul>
            </section>
            <section className="nav-item">
              <a className="title" href="#" onClick={this.navItemClickHandler}>
              Denuncio Web
                <span className="icon icon-chevron-thin-down" />
              </a>
              <ul className="dropdown">
              <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://denuncio.liberty.cl/"
                  >
                    Automotriz
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    className="link"
                    href="https://libertyautos.freshservice.com/support/tickets/new"
                  >
                    Hogar
                  </a>
                </li>
              </ul>
            </section>
            <section className="nav-item">
              <a
                className="title"
                href="https://portaladmin.liberty.cl/pago-en-linea"
              >
                Pago en Línea
              </a>
            </section>
            <section className="nav-item">
              <a
                className="title"
                href="https://www.liberty.cl/contactanos"
              >
                Contáctanos
              </a>
            </section>
            <section className="nav-item">
              <ul className="contact">
                <li className="contact-item">
                  <a
                    href="https://api.whatsapp.com/send?phone=56940222303&text=Denunciar Siniestro"
                    className="link"
                  >
                    <span className="icon icon-whatsapp" />
                    Escríbenos
                  </a>
                </li>
                <li className="contact-item">
                  <a href="tel:6005423789" className="link">
                    <span className="icon icon-phone" />
                    Llámanos
                  </a>
                </li>
                <li className="contact-item">
                  <a
                    href="https://www.liberty.cl/servicio-al-cliente/preguntas-frecuentes"
                    className="link"
                  >
                    <span className="icon icon-location" />
                    Búscanos
                  </a>
                </li>
              </ul>
            </section>
          </nav>
        </section>
        <section className="warning_cnt">
          <section className="warning">
            <span className="strong">
              {i18next.t('ingreso.mensajeMarchaBlanca.textStrong')}
            </span>
            {i18next.t('ingreso.mensajeMarchaBlanca.text')}
          </section>
        </section>
      </>
    );
  }
}

export default Header;
