/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import './footer.scss';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.logo = require('../../../assets/img/grupo-liberty-new.png');
    this.logo2x = require('../../../assets/img/grupo-liberty-new.png');
    this.logoDefensoria = require('../../../assets/img/defensor_del_asegurado.png');
    this.logoDefensoria2x = require('../../../assets/img/defensor_del_asegurado@2x.png');
    this.footerRef = React.createRef();
  }
  render() {
    return (
      <footer className="footer_container" ref={this.footerRef}>
        <section className="footer">
          <section className="footer_logo">
            <figure className="logo">
              <a href="https://www.liberty.cl/">
                <img
                  className="logo_img"
                  src={this.logo}
                  srcSet={this.logo2x}
                  alt="Logo Liberty Blanco"
                />
              </a>
            </figure>
          </section>
          <section className="footer_links">
            <ul className="list">
              <li className="list_item">
                <strong className="title">Nuestra Compañía</strong>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/nuestra-compania/quienes-somos"
                  title="Quienes somos"
                >
                  Quiénes Somos
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/nuestra-compania/organizacion"
                  title="Organizacion"
                >
                  Organización
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/nuestra-compania/gobierno-corporativo"
                  title="Gobierno Corporativo"
                >
                  Gobierno Corporativo
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/nuestra-compania/publicaciones"
                  title="Publicaciones"
                >
                  Publicaciones
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/nuestra-compania/canal-de-integridad"
                  title="Canal de compliance e integridad"
                >
                  Canal de compliance e integridad
                </a>
              </li>              
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/nuestra-compania/terminos-de-uso-y-privacidad"
                  title="Terminos de uso"
                >
                  Términos de Uso y politica de Privacidad
                </a>
              </li>
            </ul>

            <ul className="list">
              <li className="list_item">
                <strong className="title">Nuestros Seguros</strong>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/nuestros-seguros/seguro-automotriz"
                  title="Seguros Autos"
                >
                  Seguros Automotriz
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/nuestros-seguros/seguro-de-hogar"
                  title="Seguros Hogar"
                >
                  Seguro de Hogar{' '}
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/nuestros-seguros/seguros-para-empresas"
                  title="Seguros Empresa"
                >
                  Seguros para Empresas
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/nuestros-seguros/liberty-te-aconseja"
                  title="Consejos Liberty"
                >
                  Consejos Liberty
                </a>
              </li>
            </ul>

            <ul className="list">
              <li className="list_item">
                <strong className="title">Centro de Siniestro</strong>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/centro-de-siniestros/automotriz"
                  title="Vehiculo"
                >
                  Automotriz
                </a>
              </li>              
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/centro-de-siniestros/centro-de-siniestro-de-hogar-y-otros"
                  title="Hogar y Otros"
                >
                  Hogar y Otros
                </a>
              </li>
            </ul>
            <ul className="list">
              <li className="list_item">
                <strong className="title">Servicio al Cliente</strong>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/servicio-al-cliente/nuevos-clientes"
                  title="Nuevos Clientes"
                >
                  Nuevos Clientes
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://portaladmin.liberty.cl/pago-en-linea"
                  title="Pago en Línea"
                >
                  Pago en Línea
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/contactanos"
                  title="Contáctenos"
                >
                  Contáctenos
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/servicio-al-cliente/preguntas-frecuentes"
                  title="Red de Sucursales"
                >
                  Red de Sucursales
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/servicio-al-cliente/preguntas-frecuentes"
                  title="Preguntas Frecuentes"
                >
                  Preguntas Frecuentes
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/sites/default/files/pdf/siniestros-detectados-y-no-reportados-1615-cm-x-2510-cm-pdf_0.pdf"
                  title="Siniestros no detectados y reportados"
                >
                  Siniestros no detectados, no reportados
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.ddachile.cl/"
                  title="Defensor del asegurado"
                >
                  Defensor del asegurado
                </a>
              </li>
            </ul>

            <ul className="list">
              <li className="list_item">
                <strong className="title">Accesos</strong>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/clientes"
                  title="Clientes"
                >
                  Clientes
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/corredores"
                  title="Corredores"
                >
                  Corredores
                </a>
              </li>
              <li className="list_item">
                <a
                  className="link"
                  href="https://www.liberty.cl/colaboradores"
                  title="Colaboradores"
                >
                  Colaboradores
                </a>
              </li>
            </ul>
          </section>
          <section className="footer_social">
            <p className="title">Síguenos:</p>
            <a
              className="link"
              href="https://www.facebook.com/LibertySegurosCl"
            >
              <span className="icon-facebook icon" aria-hidden="true" />
            </a>
            <a className="link" href="https://www.linkedin.com/company/liberty-compa-ia-de-seguros-chile/?originalSubdomain=cl">
              <span className="icon-linkedin2 icon" aria-hidden="true" />
            </a>
            <a
              className="link link--nobackground"
              href="https://www.ddachile.cl/Inicio.aspx"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="image"
                src={this.logoDefensoria}
                srcSet={this.logoDefensoria2x}
                alt="Logo Defensoría"
              />
            </a>
          </section>
        </section>
      </footer>
    );
  }
}

export default Footer;
